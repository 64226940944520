import React, { FC } from "react";
import { Center } from "./center";
import { theme } from "../../tailwind.config.js";
import Img, { FluidObject } from "gatsby-image";

type Props = {
  image: FluidObject;
};

export const BannerImage: FC<Props> = props => {
  const { image } = props;

  return (
    (image && (
      <div className="background-stripes">
        <Center>
          <div className="h-10 lg:h-20"></div>
        </Center>

        <div
          style={{
            backgroundImage: `linear-gradient(to right, ${theme.colors["catskill-white"]} 0%, ${theme.colors["catskill-white"]} 50%, transparent 50%)`,
          }}
        >
          <Center>
            <div className="w-full h-64 pl-5 lg:pl-40 bg-catskill-white">
              <Img
                className="object-cover w-full h-full transform -translate-x-5 -translate-y-5 lg:-translate-x-10 lg:-translate-y-10 wp-image-47"
                fluid={image}
              />
            </div>
          </Center>
        </div>
      </div>
    )) ??
    null
  );
};
