module.exports = {
  theme: {
    colors: {
      black: "#17172b",
      white: "#fff",
      "dodger-blue": "#3366ff",
      "navy-blue": "#020995",
      "catskill-white": "#f1f6f9",
      "malibu-dark": "#6498fd",
      "malibu-light": "#96ccfb",
    },

    fontFamily: {
      sans: ["Roboto", "sans-serif"],
      serif: [
        "Calisto MT",
        "Bookman Old Style",
        "Bookman",
        "Goudy Old Style",
        "Garamond",
        "Hoefler Text",
        "Bitstream Charter",
        "Georgia",
        "serif",
      ],
    },

    borderColor: {
      transparent: "transparent",
      "dodger-blue": "#3366ff",
    },

    maxHeight: {
      16: "4rem",
    },

    extend: {
      spacing: {
        18: "4.5rem",
        120: "30rem",
      },
    },
  },
  variants: {},
};
