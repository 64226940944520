import React, { FC, useState } from "react";
import { motion } from "framer-motion";
import { Link } from "gatsby";
import { theme } from "../../tailwind.config.js";

type Props = {
  text: string;
  to: string;
  type: string;
};

const MotionLink = motion.custom(Link);

export const Button: FC<Props> = props => {
  const [isHovered, setIsHovered] = useState(false);
  const [hasMounted, setHasMounted] = React.useState(false);

  React.useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null;
  }

  type Colors = {
    [key: string]: Array<string>;
  };

  const colors: Colors = {
    dark: [theme.colors.white, theme.colors["dodger-blue"]],
    light: [theme.colors["dodger-blue"], theme.colors.white],
  };

  return (
    <MotionLink
      initial="idle"
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
      animate={isHovered ? "active" : "idle"}
      variants={{
        idle: {
          backgroundImage: `linear-gradient(to right, ${
            colors[props.type][0]
          } 0%, ${colors[props.type][0]} 0%, ${colors[props.type][1]} 0%)`,
          color: colors[props.type][0],
        },
        active: {
          backgroundImage: `linear-gradient(to right, ${
            colors[props.type][0]
          } 0%, ${colors[props.type][0]} 100%, ${colors[props.type][1]} 100%)`,
          color: colors[props.type][1],
        },
      }}
      transition={{
        ease: "easeOut",
      }}
      className={`border-2 text-center inline-block font-bold no-underline p-3`}
      style={{
        minWidth: "16.25rem",
        borderColor: colors[props.type][0],
      }}
      to={props.to}
      dangerouslySetInnerHTML={{ __html: props.text }}
    />
  );
};
