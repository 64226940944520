import React, { FC, Fragment } from "react";
import { graphql } from "gatsby";
import { PageTemplateQuery } from "../../graphql-types";
import { Layout } from "../components/layout";
import { BannerImage } from "../components/banner-image";
import { Center } from "../components/center";
import { Services } from "../components/services";
import Img, { FluidObject } from "gatsby-image";
import { Button } from "../components/button";
import CaretDown from "../assets/svg/caret-down.inline.svg";

export const query = graphql`
  query PageTemplate($id: ID!) {
    wp {
      globaal {
        globalCustomFields {
          bannerImageFallback {
            sourceUrl
            localFile {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1500) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }

      page(id: $id, idType: ID) {
        layoutCustomFields {
          showServicesBlock
        }

        blocks {
          ... on Wp_CoreParagraphBlock {
            originalContent
          }

          ... on Wp_CoreHeadingBlock {
            originalContent
          }

          ... on Wp_CoreListBlock {
            originalContent
          }

          ... on Wp_CoreImageBlock {
            attributes {
              ... on Wp_CoreImageBlockAttributes {
                id
                mediaItem {
                  sourceUrl
                  localFile {
                    childImageSharp {
                      fluid(quality: 90, maxWidth: 1500) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }

          ... on Wp_CoreVideoBlock {
            originalContent
          }

          ... on Wp_CoreColumnsBlock {
            originalContent
          }

          ... on Wp_AcfBannerImageBlock {
            bannerImageCustomFields {
              type
              image {
                sourceUrl
                localFile {
                  childImageSharp {
                    fluid(quality: 90, maxWidth: 1500) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }

          ... on Wp_AcfTextAndImageColumnsBlock {
            textAndImageColumnsCustomFields {
              header
              text
              button {
                title
                url
              }
              image {
                sourceUrl
                localFile {
                  childImageSharp {
                    fluid(quality: 90, maxWidth: 1500) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }

          ... on Wp_AcfColoredTextColumnsBlock {
            coloredTextColumnsCustomFields {
              textRight
              textLeft
              headerRight
              headerLeft
              buttonRight {
                title
                url
              }
              buttonLeft {
                title
                url
              }
            }
          }
        }
      }
    }
  }
`;

type Props = {
  data: PageTemplateQuery;
};

const PageTemplate: FC<Props> = props => {
  const { data } = props;

  return (
    <Layout
      showServicesBlock={
        data.wp.page?.layoutCustomFields?.showServicesBlock || false
      }
    >
      {data.wp.page?.blocks?.map((block, i) => {
        return (
          <Fragment key={i}>
            {(() => {
              /**
               * acf/banner-image
               */
              if (block.__typename === "Wp_AcfBannerImageBlock") {
                return (
                  <BannerImage
                    image={
                      (block.bannerImageCustomFields?.type === "fallback"
                        ? props.data.wp.globaal?.globalCustomFields
                            ?.bannerImageFallback?.localFile?.childImageSharp
                            ?.fluid
                        : block.bannerImageCustomFields?.image?.localFile
                            ?.childImageSharp?.fluid) as FluidObject
                    }
                  />
                );
              }

              /**
               * acf/services
               */
              if (block.__typename === "Wp_AcfServicesBlock") {
                return <Services />;
              }

              /**
               * acf/text-and-image-columns
               */
              if (block.__typename === "Wp_AcfTextAndImageColumnsBlock") {
                const fields = block.textAndImageColumnsCustomFields;

                return (
                  <div className="background-stripes">
                    <Center>
                      <div className="grid grid-cols-1 lg:grid-cols-2 pt-5 lg:pt-20">
                        <div className="lg:pl-0 lg:pr-20 mb-20">
                          {fields?.header && (
                            <h1
                              className="text-x; lg:text-5xl mb-5"
                              dangerouslySetInnerHTML={{
                                __html: fields.header,
                              }}
                            />
                          )}

                          <p
                            dangerouslySetInnerHTML={{
                              __html: fields?.text || "",
                            }}
                          />

                          {fields?.button && (
                            <div className="pt-5">
                              <Button
                                to={fields.button.url || ""}
                                text={fields.button.title || ""}
                                type="light"
                              />
                            </div>
                          )}
                        </div>

                        <div className="ml-5 lg:ml-0 h-64 lg:h-120 bg-dodger-blue">
                          <div className="w-full h-full transform -translate-x-5 -translate-y-5 lg:-translate-x-10 lg:-translate-y-10 relative">
                            <Img
                              className="object-cover w-full h-full"
                              fluid={
                                fields?.image?.localFile?.childImageSharp
                                  ?.fluid as FluidObject
                              }
                            />

                            <div className="bg-dodger-blue text-white absolute bottom-0 right-0 px-8 py-3 flex items-center lg:hidden">
                              <span className="header-down-arrow mr-3">
                                <CaretDown />
                              </span>
                              scroll
                            </div>
                          </div>
                        </div>
                      </div>
                    </Center>
                  </div>
                );
              }

              /**
               * acf/colored-text-columns
               */
              if (block.__typename === "Wp_AcfColoredTextColumnsBlock") {
                const fields = block.coloredTextColumnsCustomFields;

                return (
                  <div className="background-stripes">
                    <Center>
                      <div className="grid grid-cols-1 lg:grid-cols-2 py-5 lg:py-20">
                        <div className="p-8 lg:p-10 bg-dodger-blue text-white">
                          {fields?.headerLeft && (
                            <h2
                              className="text-2xl text-white mb-5"
                              dangerouslySetInnerHTML={{
                                __html: fields.headerLeft,
                              }}
                            />
                          )}

                          <p
                            dangerouslySetInnerHTML={{
                              __html: fields?.textLeft || "",
                            }}
                          />

                          {fields?.buttonLeft && (
                            <div className="pt-5">
                              <Button
                                to={fields.buttonLeft.url || ""}
                                text={fields.buttonLeft.title || ""}
                                type="dark"
                              />
                            </div>
                          )}
                        </div>

                        <div className="py-10 lg:px-10">
                          {fields?.headerRight && (
                            <h2
                              className="text-2xl text-doder-blue mb-5"
                              dangerouslySetInnerHTML={{
                                __html: fields.headerRight || "",
                              }}
                            />
                          )}

                          <p
                            dangerouslySetInnerHTML={{
                              __html: fields?.textRight || "",
                            }}
                          />

                          {fields?.buttonRight && (
                            <div className="pt-5">
                              <Button
                                to={fields.buttonRight.url || ""}
                                text={fields.buttonRight.title || ""}
                                type="light"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </Center>
                  </div>
                );
              }

              /**
               * core/image
               */
              if (
                block.__typename === "Wp_CoreImageBlock" &&
                block.attributes?.__typename === "Wp_CoreImageBlockAttributes"
              ) {
                return (
                  <div className="background-stripes">
                    <Center>
                      <div
                        className="mx-auto pt-5"
                        style={{ maxWidth: "60rem" }}
                      >
                        <Img
                          fluid={
                            block.attributes.mediaItem?.localFile
                              ?.childImageSharp?.fluid as FluidObject
                          }
                        />
                      </div>
                    </Center>
                  </div>
                );
              }

              /**
               * default / fallback
               */
              return (
                <div className="background-stripes">
                  <Center>
                    <div
                      className="mx-auto pt-5"
                      style={{ maxWidth: "60rem" }}
                      dangerouslySetInnerHTML={{
                        __html:
                          ("originalContent" in block &&
                            block.originalContent) ||
                          "",
                      }}
                    />
                  </Center>
                </div>
              );
            })()}
          </Fragment>
        );
      })}
    </Layout>
  );
};

export default PageTemplate;
